export const TOGGLE_LOADING = "TOGGLE_LOADING";
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const TOGGLE_MODAL = "TOGGLE_MODAL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";
export const SHOW_SNACKBAR = "SHOW_SNACKBAR";

export const LIST_ALL_WALLETS = "LIST_ALL_WALLETS";
export const FETCH_LEDGER_SUCCESS = "FETCH_LEDGER_SUCCESS";

export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const SEND_TOKENS_SUCCESS = "SEND_TOKENS_SUCCESS";

export const FETCH_GAMES_SUCCESS = "FETCH_GAMES_SUCCESS";
export const FETCH_GAME_DETAILS_SUCCESS = "FETCH_GAME_DETAILS_SUCCESS";
export const ADD_GAMES_SUCCESS = "ADD_GAMES_SUCCESS";

export const UPDATE_GAME_REQUEST = "UPDATE_GAME_REQUEST";
export const UPDATE_GAME_SUCCESS = "UPDATE_GAME_SUCCESS";

export const TOGGLE_GAME_ACTIVE_STATUS = " TOGGLE_GAME_ACTIVE_STATUS";

export const GENERATE_QUIZ_SUCCESS = "GENERATE_QUIZ_SUCCESS";

export const FETCH_QUIZ_SUCCESS = "FETCH_QUIZ_SUCCESS";
export const FETCH_QUIZ_FAILURE = "FETCH_QUIZ_FAILURE";
export const RESET_QUIZ_DATA = "RESET_QUIZ_DATA";
export const FETCH_QUIZ_TOPICS_SUCCESS = "FETCH_QUIZ_TOPICS_SUCCESS";
export const FETCH_QUIZ_TOPICS_FAILURE = "FETCH_QUIZ_TOPICS_FAILURE";
export const DELETE_QUIZ_SUCCESS = "DElETE_QUIZ_SUCCESS";
export const UPDATE_QUIZ_SUCCESS = "UPDATE_QUIZ_SUCCESS";


export const FETCH_REWARDS_SUCCESS = 'FETCH_REWARDS_SUCCESS';
export const CREATE_REWARD_SUCCESS = 'CREATE_REWARD_SUCCESS';
export const ACTIVATE_REWARD_SUCCESS = 'ACTIVATE_REWARD_SUCCESS';

export const CREATE_CAMPAIGN_SUCCESS = "CREATE_CAMPAIGN_SUCCESS";
export const FETCH_CAMPAIGN_SUCCESS = 'FETCH_CAMPAIGN_SUCCESS';
export const ACTIVATE_CAMPAIGN_SUCCESS = 'ACTIVATE_CAMPAIGN_SUCCESS';
export const UPDATE_CAMPAIGN_SUCCESS = 'UPDATE_CAMPAIGN_SUCCESS';

export const FETCH_CAMPAIGN_DETAILS_SUCCESS = 'FETCH_CAMPAIGN_DETAILS_SUCCESS';
export const LIST_CAMPAIGN_GAMES_SUCCESS = 'LIST_CAMPAIGN_GAMES_SUCCESS ';

export const FETCH_ANALYTICS_SUCCESS = 'FETCH_ANALYTICS_SUCCESS';
