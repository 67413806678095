export const LIST_ALL_GAMES = `query ListAllGames($game: GetAllGamesDto!) {
  list_all_games(game: $game) {
    data {
      _id
      name
      game_code
      is_active
      total_levels
    }
  }
}`;


export const ADD_GAME = `
mutation AddNewGame($game: AddNewGameDto!) {
  add_new_game(game: $game) {
      message
    }
  }
`;

export const UPDATE_GAME = `
  mutation UpdateGame($game: UpdateGameDto!) {
    update_game(game: $game) {
      message
    }
  }
`;

export const GAME_DETAILS = `
  query GameDetails($gameId: String!) {
    game_details(game: { _id: $gameId }) {
      data {
        _id
        name
        game_code
        include_ai_questions
        campaign {
          _id
          name
        }
        game_url
        levels {
          _id
          level
          tokens_required
          token_network
          price_amount
          price_network
          total_questions
          topics {
            data {
              _id
              name
            }
          }
          is_active
          spin_time
          question
          options
          answer
          answer_time
          price_details {
            prices {
              network
              amount
              type
              name
              _id
              id
            }
          }
          questions {
            data {
              question
              options
              answer
              answer_time
            }
          }
        }
      }
    }
  }
`;

export const ACTIVATE_GAME = `
  mutation ActivateGame($game: IdOnlyDto!) {
    activate_game(game: $game) {
      message
    }
  }
`;
