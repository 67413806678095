import React, { useCallback, useEffect, useState } from "react";
import {
  Backdrop,
  CircularProgress,
  TextField,
  Button,
  Grid,
  makeStyles,
  FormControlLabel,
  Checkbox,
  MenuItem,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { fetchGameDetails, updategame } from "store/actions/gameAction";
import { useParams } from "../../../../node_modules/react-router-dom/cjs/react-router-dom.min";
import { fetchQuizTopics } from "store/actions/topicAction";
import { fetchAllRewards } from "store/actions/rewardaction";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  modalstyle: {
    maxHeight: "100vh",
    overflowY: "auto",
    width: "80%",
    padding: theme.spacing(3),
    margin: "auto",
    backgroundColor: "white",
    borderRadius: theme.shape.borderRadius,
  },
}));

const networks = ["funq", "solana"];
const INITIAL_NETWORKS = ["funq", "solana"];

const UpdateGameModal = ({ gameData, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [currentLevelIndex, setCurrentLevelIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [gameName, setGameName] = useState(gameData?.name || "");
  const [gameCode, setGameCode] = useState("");
  const [campaignId, setCampaignId] = useState(gameData?.campaign?._id || "");
  const [includeai, setIncludeai] = useState(() => {
    if (gameData?.game_code === "spin_wheel") {
      return false;
    }
    return gameData?.include_ai_questions !== null &&
      gameData?.include_ai_questions !== undefined
      ? Boolean(gameData.include_ai_questions)
      : true;
  });

  const [tokenAmount, setTokenAmount] = useState(
    parseFloat(gameData?.token_amount) || 0
  );
  const [tokenNetwork, setTokenNetwork] = useState(
    gameData?.token_network || networks[0]
  );
  const [totalCount, setTotalCount] = useState(
    parseInt(gameData?.total_count) || 0
  );
  const [priceNetwork, setPriceNetwork] = useState(
    gameData?.level?.price_network || networks[0]
  );
  const [gameLevels, setGameLevels] = useState(gameData?.levels || []);
  const { _id } = useParams();

  const [selectedTokenNetworks, setSelectedTokenNetworks] = useState(
    gameLevels.map((level) => level.token_network)
  );
  const reward = useSelector((state) => state.reward.data);
  const [selectedPriceNetworks, setSelectedPriceNetworks] = useState(
    gameLevels.flatMap((level) => level.prices.map((price) => price.network))
  );
  const [priceNetworks, setPriceNetworks] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const { topic } = useSelector((state) => state.topic);

  useEffect(() => {
    const variables = {
      page_number: 1,
      page_size: 100,
      is_for_dropdown: true,
    };
    if (!reward.length) {
      dispatch(fetchAllRewards(variables));
    }
  }, [dispatch, reward.length]);

  useEffect(() => {
    if (reward && reward.length > 0) {
      const rewardNetworks = reward.map((reward) => reward.name); // Use 'name' field from fetched data
      setPriceNetworks((prevNetworks) => [
        ...new Set([
          ...prevNetworks,
          ...INITIAL_NETWORKS /*...rewardNetworks*/,
        ]),
      ]);
    }
  }, [reward]);

  useEffect(() => {
    if (!topic.length) {
      dispatch(fetchQuizTopics());
    }
  }, [dispatch, topic.length]);

  useEffect(() => {
    // Initialize game levels with selected topics from gameData
    if (gameData?.levels) {
      setGameLevels(
        gameData.levels.map((level) => ({
          ...level,
          topics: level.topics.map((topic) =>
            typeof topic === "object" ? topic._id : topic
          ),
        }))
      );
    }
  }, [gameData]);

  const handleTopicsChange = useCallback((levelIndex, event) => {
    const { value } = event.target;
    const selectedTopicIds = Array.isArray(value) ? value : [value];
    setGameLevels((prevLevels) => {
      const newLevels = [...prevLevels];
      newLevels[levelIndex].topics = [...new Set(selectedTopicIds)];
      return newLevels;
    });
  }, []);

  const handleTokenNetworkChange = (levelIndex, value) => {
    // Update gameLevels directly
    const newGameLevels = [...gameLevels];
    newGameLevels[levelIndex].token_network = value;
    setGameLevels(newGameLevels);

    // Update selectedTokenNetworks
    const newSelectedTokenNetworks = [...selectedTokenNetworks];
    newSelectedTokenNetworks[levelIndex] = value;
    setSelectedTokenNetworks(newSelectedTokenNetworks);
  };

  const handlePriceNetworkChangeForQuiz = (levelIndex, value) => {
    const newGameLevels = [...gameLevels];
    newGameLevels[levelIndex].price_network = value; // Directly update price_network
    setGameLevels(newGameLevels);
  };
  const handlePriceNetworkChange = (levelIndex, priceIndex, value) => {
    // Define valid networks
    const validNetworks = [...INITIAL_NETWORKS];
    // Find the selected reward from the fetched data
    const selectedReward = reward.find((r) => r.name === value);

    console.log("Selected network value:", value);
    console.log("Selected reward:", selectedReward);

    // Update game levels with new price network values
    const updatedGameLevels = gameLevels.map((level, index) => {
      if (index === levelIndex) {
        const updatedPrices = level.prices.map((price, idx) => {
          if (idx === priceIndex) {
            if (validNetworks.includes(value)) {
              // Handle predefined networks
              return {
                ...price,
                network: value, // Predefined network
                amount: 0, // Set default amount
                type: "token", // Set default type
                id: "", // Ensure id is null for predefined networks
              };
            } else if (selectedReward) {
              // Handle fetched rewards
              return {
                ...price,
                amount: parseFloat(selectedReward.amount) || 0,
                type: "reward", // Set type for fetched rewards
                id: selectedReward._id, // ID should be null for fetched rewards
                network: "", // Network is empty for fetched rewards
              };
            } else {
              // Handle case where value is neither a valid network nor a fetched reward
              return {
                ...price,
                network: "No Network",
                amount: 0,
                type: "",
                id: "",
              };
            }
          }
          return price;
        });

        return {
          ...level,
          prices: updatedPrices,
        };
      }
      return level;
    });

    console.log("Updated game levels:", updatedGameLevels);
    setGameLevels(updatedGameLevels);
  };

  const handleSwitchChange = (event) => {
    setIncludeai(event.target.checked);
  };

  const validateQuestion = (question) => {
    const { question: q, options, answer } = question;
    return q && options.length > 0 && options.every((opt) => opt) && answer;
  };

  const isAddLevelDisabled = gameLevels.some((level) => {
    // Common fields
    const commonFieldsEmpty =
      !level.level || !level.tokens_required || !level.token_network;

    // Additional fields for quiz
    const quizFieldsEmpty =
      gameCode === "quiz" &&
      (!level.price_amount ||
        !level.price_network ||
        !level.total_questions ||
        level.questions.some(
          (q) =>
            !q.question ||
            q.options.some((o) => !o) ||
            !q.answer ||
            !q.answer_time
        ));

    // Additional fields for spin_wheel
    const spinWheelFieldsEmpty =
      gameCode === "spin_wheel" &&
      (!level.spin_time ||
        level.prices.some((p) => !p.amount || !p.network || !p.type));
    console.log("Current level prices:", level.prices);

    return commonFieldsEmpty || quizFieldsEmpty || spinWheelFieldsEmpty;
  });

  console.log("isAddLevelDisabled:", isAddLevelDisabled); // Debugging line

  const isAddQuestionDisabled =
    !gameLevels[currentLevelIndex]?.questions.every(validateQuestion);

  const isAddOptionDisabled = (levelIndex, questionIndex) => {
    const currentQuestion = gameLevels[levelIndex]?.questions[questionIndex];
    if (!currentQuestion?.options.length) {
      // Allow adding an option if there are no options initially
      return false;
    }
    const lastOption =
      currentQuestion.options[currentQuestion.options.length - 1];
    // Allow adding an option if the last option is filled
    return !lastOption;
  };

  const handleLevelChange = (levelIndex, field, value) => {
    const updatedGameLevels = gameLevels.map((level, index) => {
      if (index === levelIndex) {
        return { ...level, [field]: value };
      }
      return level;
    });
    setGameLevels(updatedGameLevels);
  };

  const handlePriceChange = (levelIndex, priceIndex, field, value) => {
    const updatedGameLevels = gameLevels.map((level, index) => {
      if (index === levelIndex) {
        const updatedPrices = level.prices ? [...level.prices] : [];
        updatedPrices[priceIndex] = {
          ...updatedPrices[priceIndex],
          [field]: value,
        };
        return {
          ...level,
          prices: updatedPrices,
        };
      }
      return level;
    });
    setGameLevels(updatedGameLevels);
  };
  const handleQuestionChange = (levelIndex, questionIndex, field, value) => {
    const updatedGameLevels = gameLevels.map((level, index) => {
      if (index === levelIndex) {
        const updatedQuestions = [...level.questions];
        updatedQuestions[questionIndex] = {
          ...updatedQuestions[questionIndex],
          [field]: value,
        };
        return {
          ...level,
          questions: updatedQuestions,
        };
      }
      return level;
    });
    setGameLevels(updatedGameLevels);
  };

  const handleAddLevel = () => {
    if (!isAddLevelDisabled) {
      setGameLevels((prevLevels) => [
        ...prevLevels,
        {
          level: prevLevels.length + 1,
          tokens_required: 0,
          token_network: "",
          price_amount: 0,
          price_network: "",
          total_questions: 10,
          topics: [],
          spin_time: 0,
          prices: [{ network: "", amount: 0, type: "", id: "" }],
          questions: [
            {
              question: "",
              options: [],
              answer: "",
              answer_time: 0,
            },
          ],
        },
      ]);

      setCurrentLevelIndex((prevIndex) => prevIndex + 1);
    }
  };

  const addNewPrice = (levelIndex) => {
    const updatedGameLevels = [...gameLevels];
    updatedGameLevels[levelIndex].prices.push({
      network: "",
      amount: "",
      type: "",
      id: "",
    });
    setGameLevels(updatedGameLevels);
  };

  const isAddPriceDisabled = (levelIndex) => {
    const level = gameLevels[levelIndex];

    return level.prices.some((price) => {
      // A price is invalid if both `network` and `id` are empty, or if `amount` is invalid
      return (
        (price.network === "" && !price.id) ||
        price.amount <= 0 ||
        price.type === ""
      );
    });
  };

  const addNewQuestion = (levelIndex) => {
    if (!isAddQuestionDisabled) {
      const newQuestion = {
        question: "",
        options: [],
        answer: "",
        answer_time: 0,
      };
      const updatedGameLevels = gameLevels.map((level, index) => {
        if (index === levelIndex) {
          const updatedQuestions = [...level.questions, newQuestion];
          return {
            ...level,
            questions: updatedQuestions,
          };
        }
        return level;
      });
      setGameLevels(updatedGameLevels);
    }
  };

  const removeLevel = (levelIndex) => {
    const updatedGameLevels = gameLevels.filter(
      (level, index) => index !== levelIndex
    );
    setGameLevels(updatedGameLevels);
  };

  const removeQuestion = (levelIndex, questionIndex) => {
    const updatedGameLevels = gameLevels.map((level, index) => {
      if (index === levelIndex) {
        const updatedQuestions = level.questions.filter(
          (question, idx) => idx !== questionIndex
        );
        return {
          ...level,
          questions: updatedQuestions,
        };
      }
      return level;
    });
    setGameLevels(updatedGameLevels);
  };

  const removePrice = (levelIndex, priceIndex) => {
    const updatedGameLevels = gameLevels.map((level, index) => {
      if (index === levelIndex) {
        const updatedPrices = level.prices.filter(
          (price, idx) => idx !== priceIndex
        );
        return {
          ...level,
          prices: updatedPrices,
        };
      }
      return level;
    });
    setGameLevels(updatedGameLevels);
  };

  const addNewOption = (levelIndex, questionIndex) => {
    const updatedGameLevels = gameLevels.map((level, lIndex) => {
      if (lIndex === levelIndex) {
        const updatedQuestions = [...level.questions];
        updatedQuestions[questionIndex] = {
          ...updatedQuestions[questionIndex],
          options: [...updatedQuestions[questionIndex].options, ""], // Add an empty option
        };
        return {
          ...level,
          questions: updatedQuestions,
        };
      }
      return level;
    });
    setGameLevels(updatedGameLevels);
  };

  const handleRemoveOption = (levelIndex, questionIndex, optionIndex) => {
    const updatedGameLevels = [...gameLevels];
    const updatedQuestions = [...updatedGameLevels[levelIndex].questions];
    updatedQuestions[questionIndex].options.splice(optionIndex, 1);
    updatedGameLevels[levelIndex].questions = updatedQuestions;
    setGameLevels(updatedGameLevels);
  };

  const validateButton = () => {
    if (loading) {
      // If loading, button should be disabled
      setIsButtonDisabled(true);
    } else {
      if (gameData?.game_code === "spin_wheel") {
        // Always enable the button for spin_wheel
        setIsButtonDisabled(false);
      } else {
        // For other game codes (like "quiz")
        if (includeai) {
          // If include_ai_questions is true, enable the button
          setIsButtonDisabled(false);
        } else {
          // If include_ai_questions is false, enable the button only if there are questions
          const hasQuestions = gameLevels.some(
            (level) => level.questions && level.questions.length > 0
          );
          setIsButtonDisabled(!hasQuestions);
        }
      }
    }
  };

  // useEffect to update button state
  useEffect(() => {
    validateButton();
  }, [loading, includeai, gameLevels]);

  const handleOptionChange = (
    levelIndex,
    questionIndex,
    optionIndex,
    value
  ) => {
    const updatedGameLevels = gameLevels.map((level, lIndex) => {
      if (lIndex === levelIndex) {
        const updatedQuestions = [...level.questions];
        const updatedOptions = [...updatedQuestions[questionIndex].options];
        updatedOptions[optionIndex] = value;
        updatedQuestions[questionIndex] = {
          ...updatedQuestions[questionIndex],
          options: updatedOptions,
        };
        return {
          ...level,
          questions: updatedQuestions,
        };
      }
      return level;
    });
    setGameLevels(updatedGameLevels);
  };

  const handleSubmit = async () => {
    setLoading(true);

    const updatedGameLevels = gameLevels.map((level) => ({
      ...level,
      level: parseInt(level.level),
      tokens_required: parseFloat(level.tokens_required),
      price_amount: level.price_amount ? parseFloat(level.price_amount) : 0,
      token_network: level.token_network || tokenNetwork,
      topics: [
        ...new Set(
          level.topics.map((topic) =>
            typeof topic === "object" ? topic._id : topic
          )
        ),
      ],
      total_questions: level.total_questions
        ? parseFloat(level.total_questions)
        : 10,
      questions:
        level?.questions?.map((question) => ({
          ...question,
          options: question.options || [],
          answer_time: parseFloat(question.answer_time),
          answer: question.answer,
          question: question.question,
        })) || [],
      spin_time: level.spin_time ? parseFloat(level.spin_time) : 0,
      prices: level.prices.map((price) => {
        const filteredPrice = {
          ...price,
          amount: parseFloat(price.amount) || 0,
          network: price.network || "", // Ensure network is empty for fetched rewards
          type: price.type || "",
          id: price.id && price.id.length === 24 ? price.id : null, // Only include valid ObjectId
        };
        // Filter out null values and empty strings
        return Object.fromEntries(
          Object.entries(filteredPrice).filter(
            ([_, v]) => v != null && v !== ""
          )
        );
      }),
    }));

    const updatedGame = {
      _id: gameData._id,
      name: gameName,
      game_code: gameData.game_code,
      include_ai_questions: includeai,
      campaign_id: campaignId || "",
      token_amount: parseFloat(tokenAmount),
      token_network: tokenNetwork,
      total_count: parseInt(totalCount),
      game_levels: updatedGameLevels,
    };

    try {
      await dispatch(updategame(updatedGame)).then(() => {
        dispatch(fetchGameDetails(_id));
      });
      setLoading(false);
      onClose();
    } catch (error) {
      console.error("Error updating game:", error);
      setLoading(false);
    }
  };

  return (
    <div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={2} className={classes.modalstyle}>
        <Grid item xs={12}>
          <TextField
            label="Game Name"
            variant="filled"
            fullWidth
            value={gameName}
            onChange={(e) => setGameName(e.target.value)}
            InputLabelProps={{
              shrink: true,
              style: { paddingBottom: "8px" }, // Adjust as needed
            }}
            InputProps={{
              style: { paddingTop: "8px" }, // Adjust as needed
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Token Amount"
            variant="filled"
            fullWidth
            type="number"
            value={tokenAmount}
            onChange={(e) => setTokenAmount(e.target.value)}
            InputLabelProps={{
              shrink: true,
              style: { paddingBottom: "8px" }, // Adjust as needed
            }}
            InputProps={{
              style: { paddingTop: "8px" },
              inputProps: { min: 0.1, step: 0.01 }, // Adjust as needed
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            select
            fullWidth
            label="Token Network"
            variant="filled"
            value={tokenNetwork}
            onChange={(e) => setTokenNetwork(e.target.value)}
            helperText="Select  a token network"
            InputLabelProps={{
              shrink: true,
              style: { paddingBottom: "8px" }, // Adjust as needed
            }}
            InputProps={{
              style: { paddingTop: "8px" }, // Adjust as needed
            }}
          >
            {networks.map((network) => (
              <MenuItem key={network} value={network}>
                {network}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Total Count"
            variant="filled"
            fullWidth
            type="number"
            value={totalCount}
            onChange={(e) => setTotalCount(e.target.value)}
            InputLabelProps={{
              shrink: true,
              style: { paddingBottom: "8px" }, // Adjust as needed
            }}
            InputProps={{
              style: { paddingTop: "8px" }, // Adjust as needed
            }}
          />
        </Grid>
        {gameData?.game_code === "quiz" && (
          <Grid item xs={12} md={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={includeai}
                  onChange={handleSwitchChange}
                  // Optional: Apply styles to ensure visibility
                  style={{ color: "blue", backgroundColor: "primary" }}
                />
              }
              label="Include AI Questions"
            />
          </Grid>
        )}
        {gameLevels?.map((level, levelIndex) => (
          <Grid container spacing={2} key={levelIndex}>
            <Grid item xs={12}>
              <h4>Level {levelIndex + 1}</h4>
              <Button
                size="small"
                style={{ backgroundColor: "red", color: "white" }}
                onClick={() => removeLevel(levelIndex)}
              >
                Remove Level
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label={`Tokens Required for Level ${level.level}`}
                variant="filled"
                fullWidth
                type="number"
                value={level.tokens_required}
                onChange={(e) =>
                  handleLevelChange(
                    levelIndex,
                    "tokens_required",
                    e.target.value
                  )
                }
                InputLabelProps={{
                  shrink: true,
                  style: { paddingBottom: "8px" }, // Adjust as needed
                }}
                InputProps={{
                  style: { paddingTop: "8px" }, // Adjust as needed
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                select
                fullWidth
                label="Token Network"
                variant="filled"
                value={level.token_network}
                onChange={(e) =>
                  handleTokenNetworkChange(levelIndex, e.target.value)
                }
                helperText="Select a token network"
                InputLabelProps={{
                  shrink: true,
                  style: { paddingBottom: "8px" }, // Adjust as needed
                }}
                InputProps={{
                  style: { paddingTop: "8px" }, // Adjust as needed
                }}
              >
                {networks.map((network) => (
                  <MenuItem key={network} value={network}>
                    {network}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {gameData?.game_code === "spin_wheel" && (
              <>
                <Grid item xs={12} md={6}>
                  <TextField
                    label={`Spin Time for Level ${level.level}`}
                    variant="filled"
                    fullWidth
                    type="number"
                    value={level.spin_time || ""}
                    onChange={(e) =>
                      handleLevelChange(levelIndex, "spin_time", e.target.value)
                    }
                    InputLabelProps={{
                      shrink: true,
                      style: { paddingBottom: "8px" }, // Adjust as needed
                    }}
                    InputProps={{
                      style: { paddingTop: "8px" }, // Adjust as needed
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <h5>Prizes for Level {level.level}</h5>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => addNewPrice(levelIndex)}
                    disabled={isAddPriceDisabled(levelIndex)}
                  >
                    Add Prize
                  </Button>
                  {level.prices &&
                    level.prices.map((price, priceIndex) => (
                      <Grid
                        container
                        spacing={2}
                        key={priceIndex}
                        style={{ marginTop: "10px" }}
                      >
                        <Grid item xs={12} md={4}>
                          <TextField
                            select
                            fullWidth
                            label="Prizes"
                            variant="filled"
                            value={
                              price.id
                                ? reward.find((r) => r._id === price.id)?.name
                                : price.network
                            }
                            onChange={(e) =>
                              handlePriceNetworkChange(
                                levelIndex,
                                priceIndex,
                                e.target.value
                              )
                            }
                            helperText="Select a prize"
                            InputLabelProps={{
                              shrink: true,
                              style: { paddingBottom: "8px" }, // Adjust as needed
                            }}
                            InputProps={{
                              style: { paddingTop: "8px" }, // Adjust as needed
                            }}
                          >
                            {priceNetworks.map((network) => (
                              <MenuItem key={network} value={network}>
                                {network}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            label={`Prize Amount for Level ${level.level}`}
                            variant="filled"
                            fullWidth
                            type="number"
                            value={price.amount}
                            onChange={(e) =>
                              handlePriceChange(
                                levelIndex,
                                priceIndex,
                                "amount",
                                e.target.value
                              )
                            }
                            InputLabelProps={{
                              shrink: true,
                              style: { paddingBottom: "8px" }, // Adjust as needed
                            }}
                            InputProps={{
                              inputProps: { min: 0.1, step: 0.01 },
                              style: { paddingTop: "8px" }, // Adjust as needed
                            }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Button
                            size="small"
                            onClick={() => removePrice(levelIndex, priceIndex)}
                            style={{ backgroundColor: "red", color: "white" }}
                          >
                            Remove Prize
                          </Button>
                        </Grid>
                      </Grid>
                    ))}
                </Grid>
              </>
            )}
            {gameData?.game_code === "quiz" && (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Total Questions"
                      variant="filled"
                      fullWidth
                      value={
                        level.total_questions
                          ? parseFloat(level.total_questions)
                          : 0
                      }
                      onChange={(e) => {
                        handleLevelChange(
                          levelIndex,
                          "total_questions",
                          e.target.value
                        );
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: { paddingBottom: "8px" }, // Adjust as needed
                      }}
                      InputProps={{
                        style: { paddingTop: "8px" }, // Adjust as needed
                        inputProps: {
                          min: 1, // Set minimum value
                          type: "number", // Ensure the input is numeric
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} key={levelIndex}>
                    <TextField
                      select
                      label="Topics"
                      variant="filled"
                      value={level.topics}
                      onChange={(e) => handleTopicsChange(levelIndex, e)}
                      SelectProps={{
                        multiple: true,
                      }}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                        style: { paddingBottom: "8px" },
                      }}
                      InputProps={{
                        style: { paddingTop: "8px" },
                      }}
                    >
                      {topic.map((topic) => (
                        <MenuItem key={topic._id} value={topic._id}>
                          {topic.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Prize Amount"
                      variant="filled"
                      fullWidth
                      type="number"
                      value={
                        level.price_amount ? parseFloat(level.price_amount) : 0
                      }
                      onChange={(e) =>
                        handleLevelChange(
                          levelIndex,
                          "price_amount",
                          e.target.value
                        )
                      }
                      InputLabelProps={{
                        shrink: true,
                        style: { paddingBottom: "8px" }, // Adjust as needed
                      }}
                      InputProps={{
                        style: { paddingTop: "8px" }, // Adjust as needed
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      select
                      fullWidth
                      label="Prize Network"
                      variant="filled"
                      value={level.price_network}
                      onChange={(e) =>
                        handlePriceNetworkChangeForQuiz(
                          levelIndex,
                          e.target.value
                        )
                      }
                      helperText="Select a prize network"
                      InputLabelProps={{
                        shrink: true,
                        style: { paddingBottom: "8px" }, // Adjust as needed
                      }}
                      InputProps={{
                        style: { paddingTop: "8px" }, // Adjust as needed
                      }}
                    >
                      {networks.map((network) => (
                        <MenuItem key={network} value={network}>
                          {network}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  {level?.questions?.map((question, questionIndex) => (
                    <Grid container spacing={2} key={questionIndex}>
                      <Grid item xs={12}>
                        <h5>Question {questionIndex + 1}</h5>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label={`Question ${questionIndex + 1}`}
                          variant="filled"
                          fullWidth
                          value={question.question}
                          onChange={(e) =>
                            handleQuestionChange(
                              levelIndex,
                              questionIndex,
                              "question",
                              e.target.value
                            )
                          }
                          InputLabelProps={{
                            shrink: true,
                            style: { paddingBottom: "8px" }, // Adjust as needed
                          }}
                          InputProps={{
                            style: { paddingTop: "8px" }, // Adjust as needed
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {question.options.map((option, optionIndex) => (
                          <Grid
                            container
                            spacing={2}
                            key={optionIndex}
                            alignItems="center"
                          >
                            <Grid item xs={10}>
                              <TextField
                                label={`Option ${optionIndex + 1}`}
                                variant="filled"
                                fullWidth
                                value={option}
                                onChange={(e) =>
                                  handleOptionChange(
                                    levelIndex,
                                    questionIndex,
                                    optionIndex,
                                    e.target.value
                                  )
                                }
                                InputLabelProps={{
                                  shrink: true,
                                  style: { paddingBottom: "8px" }, // Adjust as needed
                                }}
                                InputProps={{
                                  style: { paddingTop: "8px" }, // Adjust as needed
                                }}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <Button
                                size="small"
                                style={{
                                  backgroundColor: "red",
                                  color: "white",
                                }}
                                onClick={() =>
                                  handleRemoveOption(
                                    levelIndex,
                                    questionIndex,
                                    optionIndex
                                  )
                                }
                              >
                                Remove Option
                              </Button>
                            </Grid>
                          </Grid>
                        ))}
                        <Grid item xs={12} style={{ marginTop: "20px" }}>
                          <Button
                            size="small"
                            color="primary"
                            variant="contained"
                            onClick={() =>
                              addNewOption(levelIndex, questionIndex)
                            }
                            disabled={isAddOptionDisabled(
                              levelIndex,
                              questionIndex
                            )}
                          >
                            Add Option
                          </Button>
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          label={`Answer ${questionIndex + 1}`}
                          variant="filled"
                          fullWidth
                          value={question.answer}
                          onChange={(e) =>
                            handleQuestionChange(
                              levelIndex,
                              questionIndex,
                              "answer",
                              e.target.value
                            )
                          }
                          InputLabelProps={{
                            shrink: true,
                            style: { paddingBottom: "8px" }, // Adjust as needed
                          }}
                          InputProps={{
                            style: { paddingTop: "8px" }, // Adjust as needed
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label={`Answer Time for Question ${
                            questionIndex + 1
                          }`}
                          variant="filled"
                          fullWidth
                          type="number"
                          value={question.answer_time}
                          inputProps={{ min: 3 }}
                          onChange={(e) => {
                            const value = Math.max(3, e.target.value);
                            handleQuestionChange(
                              levelIndex,
                              questionIndex,
                              "answer_time",
                              value
                            );
                          }}
                          InputLabelProps={{
                            shrink: true,
                            style: { paddingBottom: "8px" }, // Adjust as needed
                          }}
                          InputProps={{
                            style: { paddingTop: "8px" }, // Adjust as needed
                          }}
                        />
                      </Grid>
                      {questionIndex === level.questions.length - 1 && (
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: "flex",
                            gap: "16px",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            variant="contained"
                            size="small"
                            onClick={() => addNewQuestion(levelIndex)}
                            color="primary"
                          >
                            Add Question
                          </Button>
                          <Button
                            size="small"
                            onClick={() =>
                              removeQuestion(levelIndex, questionIndex)
                            }
                            style={{ backgroundColor: "red", color: "white" }}
                          >
                            Remove Question
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
          </Grid>
        ))}
        <Grid item xs={12} style={{ marginTop: "20px" }}>
          <Button
            size="small"
            variant="contained"
            onClick={handleAddLevel}
            color="primary"
            disabled={isAddLevelDisabled}
          >
            Add Level
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={isButtonDisabled || loading}
            size="small"
          >
            Update Game
          </Button>
          <Button
            size="small"
            variant="contained"
            color="default"
            onClick={onClose}
            style={{ marginLeft: "16px" }}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default UpdateGameModal;
