import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Box,
  Grid,
  Card,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import CustomHeader from "components/Headers/CustomHeader";
import componentStyles from "assets/theme/views/admin/tables.js";
import { fetchAllAnalytics } from "store/actions/AnalyticAction";


const useStyles = makeStyles(componentStyles);

const Analytics = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const analyticsData = useSelector((state) => state.analytics.analyticsList);
  const loading = useSelector((state) => state.analytics.loading);
  const error = useSelector((state) => state.analytics.error);

  useEffect(() => {
    // Dispatch an action to load initial analytics data
    dispatch(fetchAllAnalytics({ page_number: 1, page_size: 10 }));
  }, [dispatch]);

  

  return (
    <>
      <CustomHeader />
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container spacing={3}>
          {loading ? (
            <CircularProgress style={{ margin: "auto" }} />
          ) : error ? (
            <Typography variant="h6" color="error">
              {error}
            </Typography>
          ) : analyticsData.length === 0 ? (
            <Typography variant="h6">No analytics data available.</Typography>
          ) : (
            analyticsData.map((analytic, index) => (
              <Grid item xs={12} md={12} key={index}>
                <Card style={{ padding: "1em" }}>
                  <Typography variant="h4">IP Address: {analytic.ip_address}</Typography>
                  <Typography variant="body1">Location: {analytic.location}</Typography>
                  <Typography variant="body1">Browser: {analytic.browser}</Typography>
                  {/* <Typography variant="body1">User ID: {analytic.user_id}</Typography> */}
                  <Typography variant="body2">
  Timestamp: {new Date(analytic.timestamp * 1000).toLocaleString()}
</Typography>
                 
                  <Typography variant="body2">Date: {analytic.date}</Typography>
                  <Typography variant="body2">Device Type: {analytic.device_type}</Typography>
                  <Typography variant="body2">Device Vendor: {analytic.device_vendor}</Typography>
                  <Typography variant="body2">Device Model: {analytic.device_model}</Typography>
                  <Typography variant="body2">Type: {analytic.type}</Typography>
                </Card>
              </Grid>
            ))
          )}
        </Grid>

      </Container>
    </>
  );
};

export default Analytics;
